import { useHref } from '@retail/backoffice-urls/src';
import { Link } from 'react-router-dom';

import { PermissionChecker } from '~components/permission-checker';
import { PERMISSION } from '~constants/permission';
import { DOMAIN } from '~constants/domain';

interface Props {
  flowVersion: string;
  orderNumber: string;
  orderId: string;
}

export function OrderNumber({ flowVersion, orderNumber, orderId }: Props) {
  const href = useHref('ORDER', { orderId });

  const content = (
    <div style={{ display: 'flex' }}>
      {orderNumber}&nbsp;<strong>({flowVersion})</strong>
    </div>
  );
  return (
    <PermissionChecker
      allow={PERMISSION.QUERY_ORDER}
      domain={DOMAIN.ORDER_MANAGEMENT}
      fallback={content}
    >
      <Link to={href} target="_blank">
        {content}
      </Link>
    </PermissionChecker>
  );
}
