import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import Root from '~components/root';

if (__WEBPACK_DEV_SERVER__) {
  // eslint-disable-next-line global-require
  require('antd/dist/antd.less');
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  renderType: 'render',
  rootComponent: Root,
  // TODO: Switch to "errorBoundary (err, info, props) {" when it starts being used
  errorBoundary() {
    // Customize the root error boundary for your MFE here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
