import { MinusOutlined } from '@ant-design/icons';
import { useHref } from '@retail/backoffice-urls';

import {
  VERIFICATION_STATE_LABELS,
  VerificationState as VerificationStateOption,
} from './constants';

interface VerificationStateProps {
  orderId: string;
  verificationState: VerificationStateOption;
  qaSelector?: string;
}

export const VerificationState = ({
  verificationState,
  orderId,
  qaSelector,
}: VerificationStateProps) => {
  const orderLink = useHref('ORDER', { orderId });
  const scopedOrderLink = orderLink && `${orderLink}/verification-subtab`;
  const content = verificationState ? (
    VERIFICATION_STATE_LABELS[verificationState] ?? verificationState
  ) : (
    <MinusOutlined />
  );

  return verificationState === VerificationStateOption.COMPLETED ? (
    <a href={scopedOrderLink} target="_blank" data-qa-selector={qaSelector}>
      {content}
    </a>
  ) : (
    <span data-qa-selector={qaSelector}>{content}</span>
  );
};
