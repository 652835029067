import { get } from 'lodash/fp';
import React, { memo, ReactNode } from 'react';
import {
  Form,
  Input,
  Typography,
  InputNumber,
  InputNumberProps,
  InputProps,
} from 'antd';
import {
  FieldValues,
  useController,
  DeepRequired,
  FieldErrorsImpl,
  UseControllerProps,
} from 'react-hook-form';

import cn from './styles.less';

const { Text } = Typography;

export type FInputProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  required?: boolean;
  qaSelector: string;
  wrapperCol?: unknown;
  parse?: (value) => string | number;
  format?: (value) => string | number;
  labelCol?: { [key: string]: string | number };
  errors?: FieldErrorsImpl<DeepRequired<unknown>>;
} & (InputProps & InputNumberProps);

export const FInput = memo<FInputProps<FieldValues>>(
  ({
    wrapperCol,
    required,
    errors,
    label,
    controllerProps,
    format = (e) => e,
    parse = (e) => e,
    type,
    labelCol,
    qaSelector,
    ...restInputProps
  }) => {
    const { field } = useController(controllerProps);
    const error = get(controllerProps?.name)(errors);
    return (
      <Form.Item
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        help={error ? error?.message : null}
        validateStatus={error ? 'error' : null}
        data-qa-selector={`${qaSelector}-wrapper`}
        label={
          label ? (
            <Text data-qa-selector={`${qaSelector}-label`}>{`${label} ${
              required ? '*' : ''
            }`}</Text>
          ) : null
        }
      >
        <div className={cn.inputWrapper}>
          {type === 'number' ? (
            <InputNumber
              {...field}
              value={format(field.value)}
              data-qa-selector={`${qaSelector}-field`}
              onChange={(value) => field.onChange(parse(value))}
              {...restInputProps}
              decimalSeparator=","
            />
          ) : (
            <Input
              {...field}
              value={format(field.value)}
              data-qa-selector={`${qaSelector}-field`}
              onChange={(value) => field.onChange(parse(value))}
              {...restInputProps}
            />
          )}
        </div>
      </Form.Item>
    );
  },
);
