import { MinusOutlined } from '@ant-design/icons';
import { useHref } from '@retail/backoffice-urls';

import { APPLICATION_TYPE_LABELS } from './constants';
import { ApplicationType as ApplicationTypeEnum } from './types';

interface ApplicationTypeProps {
  orderId: string;
  applicationType: undefined | null | ApplicationTypeEnum;
  qaSelector?: string;
}

export const ApplicationType = ({
  orderId,
  applicationType,
  qaSelector,
}: ApplicationTypeProps) => {
  const orderLink = useHref('ORDER', { orderId });
  const contractLink = orderLink && `${orderLink}/contract`;

  return (
    <span data-qa-selector={qaSelector}>
      {applicationType ? (
        <a href={contractLink} target="_blank">
          {APPLICATION_TYPE_LABELS[applicationType]}
        </a>
      ) : (
        <MinusOutlined />
      )}
    </span>
  );
};
