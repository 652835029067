import { FunctionComponent, PropsWithChildren, ReactElement } from 'react';

import { DOMAIN } from '~constants/domain';
import { PERMISSION } from '~constants/permission';
import useCheckPermissions, {
  IUseCheckPermissionsProps,
} from '~hooks/acl/useCheckPermissions';

export interface PermissionCheckerProps
  extends PropsWithChildren<IUseCheckPermissionsProps> {
  fallback?: ReactElement;
}

export function PermissionChecker({
  children,
  fallback = null,
  ...permissionProps
}: PermissionCheckerProps) {
  const { isAllowed } = useCheckPermissions(permissionProps);

  if (isAllowed) {
    return <>{children}</>;
  }

  return fallback;
}

export function withPermissions<Props>(allow: PERMISSION, domain: DOMAIN) {
  return (Component: FunctionComponent<Props>) => (props?: Props) => (
    <PermissionChecker allow={allow} domain={domain}>
      <Component {...props} />
    </PermissionChecker>
  );
}
