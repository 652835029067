import { useHref } from '@retail/backoffice-urls/src';
import { LockFilled, MinusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { formatFullName } from '../utils';

import {
  CustomerSearchProjection,
  OpportunitySearchProjection,
} from '~apollo/gql-types';
import { PermissionChecker } from '~components/permission-checker';
import { PERMISSION } from '~constants/permission';
import { DOMAIN } from '~constants/domain';

type Props = {
  customer: CustomerSearchProjection;
  opportunity?: OpportunitySearchProjection;
};

export const CustomerName = ({ customer, opportunity }: Props) => {
  const href = useHref('RETAIL_CUSTOMER_CLM');

  if (customer.customerType === 'COMPANY') {
    return customer.companyName ? (
      <Tooltip placement="left" title="COMPANY">
        <LockFilled /> {customer.companyName}
      </Tooltip>
    ) : (
      <MinusOutlined />
    );
  }

  const fullName = customer && formatFullName(customer);

  if (!fullName) {
    return <MinusOutlined />;
  }

  if (!opportunity) {
    return <>{fullName}</>;
  }

  const searchParams = new URLSearchParams([
    ['customerUuid', opportunity.customerLeadId],
    ['oppUuid', opportunity.id],
  ]);

  return (
    <PermissionChecker
      allow={PERMISSION.QUERY_CUSTOMER_LEAD_OVERVIEW}
      domain={DOMAIN.PLATFORM_API}
      fallback={<div>{fullName}</div>}
    >
      <a href={`${href}?${searchParams}`} target="_blank">
        {fullName}
      </a>
    </PermissionChecker>
  );
};
