import { replace } from 'lodash';

import PackageJson from '../../../package.json';

export const ANTD_EXTERNAL_VERSION = `antd-${PackageJson.dependencies.antd}`;
export const ANTD_EXTERNAL_VERSION_LESS = replace(
  ANTD_EXTERNAL_VERSION,
  /\./g,
  '-',
);
