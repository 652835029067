import { useState } from 'react';
import { format } from 'date-fns';
import { Moment } from 'moment';
import { MinusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Popover, Tooltip } from 'antd';
import endOfDay from 'date-fns/endOfDay';
import { FINANCING_STATUSES } from '@retail/order-financing/src/constants/financingStatuses';

import { FINANCING_APPLICATION_DATE_FORMAT } from './search';

interface BankContractSignedOnProps {
  allowMarkingAsSigned: boolean;
  financingStatus: undefined | null | string;
  bankContractSignedOn: undefined | null | string;
  orderCreatedOn: undefined | null | string;
  qaSelector?: string;
  onMarkAsSigned: (date: string) => Promise<void>;
}

export const BankContractSignedOn = ({
  allowMarkingAsSigned,
  financingStatus,
  bankContractSignedOn,
  orderCreatedOn,
  qaSelector,
  onMarkAsSigned,
}: BankContractSignedOnProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<undefined | Moment>();
  const [isUpdating, setIsUpdating] = useState(false);
  const isMarkingAsSignedDisabled = ![
    FINANCING_STATUSES.PRE_APPROVED,
    FINANCING_STATUSES.APPROVED,
  ].includes(financingStatus);
  const persistedAndFormatted =
    bankContractSignedOn &&
    format(new Date(bankContractSignedOn), FINANCING_APPLICATION_DATE_FORMAT);

  const handleVisibleChange = (isVisible: boolean) => {
    if (isUpdating) {
      return;
    }

    if (!isVisible) {
      setShowDatepicker(false);
      setSelectedDate(undefined);
    }

    setShowPopover(isVisible);
  };

  const handleDateSubmit = () => {
    if (!selectedDate) {
      return setShowDatepicker(true);
    }

    const preparedDate = selectedDate.utc(true).startOf('day').toISOString();

    setIsUpdating(true);
    onMarkAsSigned(preparedDate)
      .then(() => {
        setShowPopover(false);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  if (!allowMarkingAsSigned) {
    return (
      <span data-qa-selector={qaSelector}>
        {persistedAndFormatted ?? <MinusOutlined />}
      </span>
    );
  }

  return (
    <span data-qa-selector={qaSelector}>
      {persistedAndFormatted ?? (
        <Popover
          title="Select the date"
          trigger="click"
          content={
            <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
              <DatePicker
                open={showDatepicker}
                format="DD/MM/YYYY"
                placeholder="Bank contract signed on"
                style={{ width: 250 }}
                value={selectedDate}
                disabledDate={(selected) => {
                  const selectedDate = selected.toDate();

                  return (
                    selectedDate > endOfDay(new Date()) ||
                    (orderCreatedOn != null &&
                      selectedDate < new Date(orderCreatedOn))
                  );
                }}
                data-qa-selector="set-bank-contract-signed-date-datepicker"
                onOpenChange={setShowDatepicker}
                onChange={setSelectedDate}
              />
              <div
                style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}
              >
                <Button
                  data-qa-selector="set-bank-contract-signed-date-cancel"
                  onClick={() => handleVisibleChange(false)}
                >
                  Cancel
                </Button>
                <Button
                  danger
                  loading={isUpdating}
                  data-qa-selector="set-bank-contract-signed-date-confirm"
                  onClick={handleDateSubmit}
                >
                  Confirm
                </Button>
              </div>
            </div>
          }
          visible={showPopover}
          onVisibleChange={(isVisible) => {
            if (!isVisible) {
              handleVisibleChange(isVisible);
            }
          }}
        >
          <Tooltip
            mouseEnterDelay={1}
            title={
              isMarkingAsSignedDisabled
                ? 'Bank contract can be marked as signed for financing ' +
                  'applications in state PRE_APPROVED or APPROVED'
                : null
            }
          >
            <Button
              type="link"
              disabled={isMarkingAsSignedDisabled}
              style={{ padding: 0 }}
              onClick={() => handleVisibleChange(true)}
            >
              Mark as signed
            </Button>
          </Tooltip>
        </Popover>
      )}
    </span>
  );
};
