import { useCallback } from 'react';

import { getGQLPermissions, hasPermissions } from './helpers';

import useUser from '~hooks/useUser';
import { DOMAIN } from '~constants/domain';
import { PERMISSION } from '~constants/permission';

export interface HasPermissionFn {
  (permission: PERMISSION, domain: DOMAIN): boolean;
}

export const useHasPermission = (): HasPermissionFn => {
  const permissionsData = useUser();

  return useCallback(
    (permission, domain) => {
      const { permissions } = permissionsData;
      const allowPerms = getGQLPermissions(permission, domain);
      return hasPermissions(permissions, allowPerms);
    },
    [permissionsData],
  );
};
