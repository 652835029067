import { useHref } from '@retail/backoffice-urls/src';
import { Link } from 'react-router-dom';

import { DOMAIN } from '~constants/domain';
import { PERMISSION } from '~constants/permission';
import { PermissionChecker } from '~components/permission-checker';

interface Props {
  adId: string;
  stockNumber: string;
}

export function StockNumber({ adId, stockNumber }: Props) {
  const href = useHref('RETAIL_AD_ITEM', { adId });

  return (
    <PermissionChecker
      allow={[PERMISSION.QUERY_AD, PERMISSION.QUERY_RETAIL_AD_BY_ID]}
      domain={DOMAIN.ORDER_MANAGEMENT}
      fallback={<span>{stockNumber}</span>}
    >
      <Link to={href} target="_blank">
        {stockNumber}
      </Link>
    </PermissionChecker>
  );
}
