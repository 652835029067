import { useSetBankContractSignedOnMutation } from '~apollo/gql-types';

export const useBankContract = () => {
  const [mutate] = useSetBankContractSignedOnMutation({
    ignoreResults: true,
  });

  const setBankContractSignedOn = async (input: {
    financingId: string;
    date: string;
  }) => {
    await mutate({ variables: input });
  };

  return {
    setBankContractSignedOn,
  };
};
