import { useState, useEffect, useMemo } from 'react';

import { ColumnConfig } from '../types';
import { DEFAULT_TABLE_COLUMNS } from '../constants';

import {
  getColumnsConfig,
  getVisibleColumns,
  persistColumns,
  getPersistedColumns,
} from './utils';

import { getUserId } from '~apollo/gql-client';

export const useColumnsConfig = () => {
  const [config, setConfig] = useState<ColumnConfig[]>(() =>
    getColumnsConfig(DEFAULT_TABLE_COLUMNS),
  );
  const visibleColumns = useMemo(() => getVisibleColumns(config), [config]);

  const handleConfigSet = (config: ColumnConfig[]) => {
    persistColumns({
      userId: getUserId(),
      columns: getVisibleColumns(config),
    });
    setConfig(config);
  };

  useEffect(() => {
    const columns = getPersistedColumns({ userId: getUserId() });

    if (columns) {
      setConfig(getColumnsConfig(columns));
    }
  }, []);

  return {
    config,
    visibleColumns,
    setConfig: handleConfigSet,
  };
};
