import { useHref } from '@retail/backoffice-urls';

import { PermissionChecker } from '~components/permission-checker';
import { PERMISSION } from '~constants/permission';
import { DOMAIN } from '~constants/domain';

interface Props {
  orderId: string;
  applicationNumber: string;
}

export function ApplicationNumber({ orderId, applicationNumber }: Props) {
  const href = useHref('ORDER_CONSUMER_FINANCING', { orderId });

  return (
    <PermissionChecker
      allow={PERMISSION.QUERY_SEARCH_ORDER_FINANCING_BY_ORDER}
      domain={DOMAIN.ORDER_MANAGEMENT}
      fallback={<div>{applicationNumber}</div>}
    >
      <a href={href} target="_blank">
        {applicationNumber}
      </a>
    </PermissionChecker>
  );
}
