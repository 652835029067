import { intersection, flow, toPairs, map, isArray, isEmpty } from 'lodash/fp';

import { DOMAIN } from '~constants/domain';

const SCHEMA = 'gqls';
const CLM_SCHEMA = 'clm';

export const hasPermissions = (userPerms, allowPerms) => {
  const result = intersection(allowPerms, userPerms);

  return !isEmpty(result);
};

export const mapToGQLPermission = (perm, domain = DOMAIN.DEFAULT) => {
  let schema = SCHEMA;

  if (domain === DOMAIN.CLM_API || domain === DOMAIN.CLM_GRANT) {
    schema = CLM_SCHEMA;
  }

  return `${schema}://${domain}/${perm}`;
};

export const getGQLPermissions = (allow, domain) => {
  const perms = isArray(allow) ? allow : [allow];

  return flow(
    toPairs,
    map(([i, perm]) =>
      mapToGQLPermission(perm, isArray(domain) ? domain[i] : domain),
    ),
  )(perms);
};
