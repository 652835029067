import { Form, Typography } from 'antd';
import 'antd/lib/date-picker/style/index';
import { RangePickerProps } from 'antd/lib/date-picker';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React, { useState } from 'react';
import { Control, FieldErrors, FieldValues, useWatch } from 'react-hook-form';
import generateRangePicker from 'antd/lib/date-picker/generatePicker/generateRangePicker';

import { ANTD_EXTERNAL_VERSION_LESS } from '~components/root/constants';

const RangePicker = generateRangePicker<Date>(dateFnsGenerateConfig);

const { Text } = Typography;

interface InputControlledProps extends Omit<RangePickerProps, 'name'> {
  required?: boolean;
  name: [startDate: string, endDate: string];
  label: string;
  labelCol?: { span: number };
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
  errors?: FieldErrors;
  qaSelector: string;
}

export const FRangeDatePicker = ({
  allowClear,
  disabled,
  required,
  name,
  label,
  labelCol,
  control,
  setValue,
  errors,
  qaSelector,
}: InputControlledProps) => {
  const [startDateName, endDateName] = name;
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, endDate] = useWatch({
    control,
    name: [startDateName, endDateName],
  });
  const anyError = errors?.[startDateName] ?? errors?.[endDateName];

  const handleChange = (values: null | [Date, Date]) => {
    const [nextStartDate, nextEndDate] = values ?? [null, null];

    setValue(startDateName, nextStartDate, { shouldDirty: true });
    setValue(endDateName, nextEndDate, { shouldDirty: true });
  };

  return (
    <Form.Item
      labelCol={labelCol}
      label={<Text>{`${label}${required ? ' *' : ''}`}</Text>}
      validateStatus={anyError ? 'error' : null}
      help={anyError?.message}
    >
      <RangePicker
        prefixCls={`${ANTD_EXTERNAL_VERSION_LESS}-picker`}
        allowClear={allowClear}
        disabled={disabled}
        open={isOpen}
        value={[startDate, endDate]}
        style={{ width: '100%' }}
        // FIXME (Probably it is a bug which was fixed in antd v4.24.6
        popupStyle={{ zIndex: 9999, display: isOpen ? 'block' : 'none' }}
        data-qa-selector={qaSelector}
        onOpenChange={setIsOpen}
        onChange={handleChange}
      />
    </Form.Item>
  );
};
