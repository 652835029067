import { FINANCING_FLOW_VERSIONS } from '@retail/order-constants';
import {
  FINANCING_STATUSES,
  FINANCING_TYPES,
} from '@retail/order-financing/src/constants';

import { isValidTransition } from './transitionMap';

import { DOMAIN } from '~constants/domain';
import { PERMISSION } from '~constants/permission';
import { HasPermissionFn } from '~hooks/acl/useHasPermission';

const getAvailableStatuses = ({ financingType }: { financingType: string }) => {
  switch (financingType) {
    case FINANCING_TYPES.EXTERNAL:
      return [
        FINANCING_STATUSES.PENDING,
        FINANCING_STATUSES.REJECTED,
        FINANCING_STATUSES.CANCELED,
        FINANCING_STATUSES.VALIDATION,
        FINANCING_STATUSES.CUSTOMER_ACTION_REQUIRED,
        FINANCING_STATUSES.SUBMITTED_TO_BANK,
        FINANCING_STATUSES.PRE_APPROVED,
        FINANCING_STATUSES.APPROVED,
      ];
    case FINANCING_TYPES.INTERNAL:
      return [
        FINANCING_STATUSES.PENDING,
        FINANCING_STATUSES.REJECTED,
        FINANCING_STATUSES.CANCELED,
        FINANCING_STATUSES.PRE_APPROVED,
        FINANCING_STATUSES.APPROVED,
        FINANCING_STATUSES.COMPLETED,
      ];
    default:
      return [];
  }
};

const hasPermissionForStatus = ({
  status,
  financingType,
  hasPermission,
}: {
  status: string;
  financingType: string;
  hasPermission: HasPermissionFn;
}) => {
  const statusSpecificPermissions = (() => {
    const canQueryRejectReasons = hasPermission(
      PERMISSION.QUERY_ORDER_FINANCE_REJECT_REASONS,
      DOMAIN.ORDER_MANAGEMENT,
    );
    const canQueryCancelReasons = hasPermission(
      PERMISSION.QUERY_ORDER_FINANCE_CANCEL_REASONS,
      DOMAIN.ORDER_MANAGEMENT,
    );

    if (financingType === FINANCING_TYPES.EXTERNAL) {
      return {
        [FINANCING_STATUSES.REJECTED]: canQueryRejectReasons,
        [FINANCING_STATUSES.CANCELED]: canQueryCancelReasons,
      };
    }

    if (financingType === FINANCING_TYPES.INTERNAL) {
      return {
        [FINANCING_STATUSES.REJECTED]:
          canQueryRejectReasons &&
          hasPermission(
            PERMISSION.MUTATION_REJECT_ORDER_FINANCING,
            DOMAIN.ORDER_MANAGEMENT,
          ),
        [FINANCING_STATUSES.CANCELED]:
          canQueryCancelReasons &&
          hasPermission(
            PERMISSION.MUTATION_CANCEL_ORDER_FINANCING,
            DOMAIN.ORDER_MANAGEMENT,
          ),
        [FINANCING_STATUSES.PRE_APPROVED]: hasPermission(
          PERMISSION.MUTATION_PRE_APPROVE_ORDER_FINANCING,
          DOMAIN.ORDER_MANAGEMENT,
        ),
        [FINANCING_STATUSES.APPROVED]: hasPermission(
          PERMISSION.MUTATION_APPROVE_ORDER_FINANCING,
          DOMAIN.ORDER_MANAGEMENT,
        ),
        [FINANCING_STATUSES.COMPLETED]: hasPermission(
          PERMISSION.MUTATION_COMPLETE_ORDER_FINANCING,
          DOMAIN.ORDER_MANAGEMENT,
        ),
      };
    }

    return {};
  })();

  return status in statusSpecificPermissions
    ? statusSpecificPermissions[status]
    : true;
};

export const getFinancingStatuses = ({
  financingType,
  financingFlowVersion,
  currentStatus,
  previousStatus,
  hasPermission,
}: {
  financingType: string;
  financingFlowVersion: string;
  currentStatus: string;
  previousStatus: string | null;
  hasPermission: HasPermissionFn;
}) => {
  const hasSuperPermissions = hasPermission(
    PERMISSION.MUTATION_SUPER_EDIT_RISK_USER_INFO,
    DOMAIN.ORDER_FINANCING_RISK_MANAGEMENT,
  );

  return getAvailableStatuses({
    financingType,
  }).filter(
    (nextStatus) =>
      hasSuperPermissions ||
      (hasPermissionForStatus({
        status: nextStatus,
        financingType,
        hasPermission,
      }) &&
        (financingFlowVersion === FINANCING_FLOW_VERSIONS.V2
          ? isValidTransition({
              previousStatus,
              currentStatus,
              nextStatus,
            })
          : true)),
  );
};
