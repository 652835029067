import { notification } from 'antd';
import { get } from 'lodash/fp';

export const REQUEST_BASE_OPTIONS = {
  onError: (error) => {
    notification.warning({
      message: 'Error',
      description: get('0.message')(error?.graphQLErrors),
    });
  },
};
