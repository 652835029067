import { FINANCING_STATUSES } from '@retail/order-financing/src/constants/financingStatuses';

import { PLAN_TYPES } from '~constants/plan-types';
import { FINANCE_FLOW_TYPE } from '~constants/flow-types';
import { RETAIL_ORDER_STATE } from '~constants/order-states';
import { Country } from '~utils/accessible-countries';

export enum TableColumn {
  Country = 'Country',
  FlowType = 'FlowType',
  FinancingType = 'FinancingType',
  AppState = 'AppState',
  AppType = 'AppType',
  OrderState = 'OrderState',
  AppNumber = 'AppNumber',
  ExternalFinancingRefNumber = 'ExternalFinancingRefNumber',
  OrderNumber = 'OrderNumber',
  StockNumber = 'StockNumber',
  Assignee = 'Assignee',
  ContractType = 'ContractType',
  PriorityRank = 'PriorityRank',
  Cluster = 'Cluster',
  FinancingProvider = 'FinancingProvider',
  SalesAgent = 'SaleAgent',
  CustomerName = 'CustomerName',
  InterestRate = 'InterestRate',
  CreditPeriod = 'CreditPeriod',
  CreditAmount = 'CreditAmount',
  PaymentType = 'PaymentType',
  PaymentStatus = 'PaymentStatus',
  MonthlyPaymentAmount = 'MonthlyPaymentAmount',
  BalloonAmount = 'BalloonAmount',
  CashAmount = 'CashAmount',
  Ppi = 'Ppi',
  Gap = 'Gap',
  VerificationForm = 'VerificationForm',
  AppCreationDate = 'AppCreationDate',
  OrderCreationDate = 'OrderCreationDate',
  HandoverDate = 'HandoverDate',
  WorkingDaysUntilHandover = 'WorkingDaysUntilHandover',
  BankReleaseSigned = 'BankReleaseSigned',
  BankSubmissionDate = 'BankSubmissionDate',
  BankContractSignedOn = 'BankContractSignedOn',
}

export enum ApplicationType {
  FastTrackApplication = 'FAST_TRACK_APPLICATION',
  ApplicationBasedOnCompleteDocumentCheck = 'APPLICATION_BASED_ON_COMPLETE_DOCUMENT_CHECK',
}

export interface ColumnConfig {
  column: TableColumn;
  isVisible: boolean;
}

export interface FilterConfig {
  page?: null | number;
  pageSize?: null | number;
  flowType?: null | FINANCE_FLOW_TYPE;
  financingType?: null | string;
  status?: null | (keyof typeof FINANCING_STATUSES)[];
  applicationType?: null | ApplicationType;
  financingCreatedOnStart?: null | Date;
  financingCreatedOnEnd?: null | Date;
  retailCountry?: null | Country[];
  externalFinancingRefNumber?: null | string;
  orderNumber?: null | string;
  stockNumber?: null | string;
  customerEmail?: null | string;
  customerLastName?: null | string;
  orderState?: null | RETAIL_ORDER_STATE[];
  paymentStatus?: null | string[];
  planType?: null | PLAN_TYPES;
  ppi?: null | boolean;
  gap?: null | boolean;
  insurance?: null | 'ppi' | 'gap' | 'no-insurance' | 'ppi+gap';
  verificationState?: null | string;
  financingProvider?: null | string[];
  assignee?: null | string[];
  salesAgent?: null | string[];
  bankReleaseSigned?: null | 'YES' | 'NO';
  handoverDateStart?: null | Date;
  handoverDateEnd?: null | Date;
  bankContractSignedOnStart?: null | Date;
  bankContractSignedOnEnd?: null | Date;
  bankSubmissionDateStart?: null | Date;
  bankSubmissionDateEnd?: null | Date;
}

export type SortOrder = 'descend' | 'ascend';

export type SortingConfig = {
  column: TableColumn;
  order: SortOrder;
};

export interface CountryOption {
  label: string;
  value: Country;
}
