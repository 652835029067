import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Tooltip } from 'antd';
import { useForm } from 'react-hook-form';

import { useFinancingRelatedOptions } from '../hooks/useFinancingRelatedOptions';

import cn from './styles.less';
import { FilterConfig, CountryOption } from './types';
import {
  FINANCING_TYPE_LABELS,
  APPLICATION_TYPE_LABELS,
  PAYMENT_STATUS_LABELS,
  VERIFICATION_STATE_LABELS,
} from './constants';

import { FInput } from '~components/form/input';
import { FRangeDatePicker } from '~components/form/range-datepicker';
import { FSelect } from '~components/form/select';
import { financingApplicationStatuses } from '~constants/financing-application-statuses';
import { financeFlowOptions } from '~constants/flow-types';
import { retailOrderOptions } from '~constants/order-states';
import { insuranceOptions, planTypesOptions } from '~constants/plan-types';

interface SearchProps {
  loading: boolean;
  defaultFilter: FilterConfig;
  initialFilter: FilterConfig;
  countryOptions: CountryOption[];
  onChange: (data: FilterConfig) => void;
}

export const FINANCING_APPLICATION_DATE_FORMAT = 'dd-MM-yyyy';
export const FINANCING_APPLICATION_DATE_TIME_FORMAT = 'dd-MM-yyyy HH:mm';

const labelsToOptions = (labels: Record<string, string>) =>
  Object.entries(labels).map(([value, label]) => ({ value, label }));

export const Search = ({
  loading,
  defaultFilter,
  initialFilter,
  countryOptions,
  onChange,
}: SearchProps) => {
  const {
    control,
    setValue,
    reset,
    handleSubmit: makeSubmitHandler,
  } = useForm({
    mode: 'all',
    defaultValues: initialFilter,
  });
  const financingRelatedOptions = useFinancingRelatedOptions();

  const handleFilterReset = () => {
    reset(defaultFilter);
    onChange(defaultFilter);
  };

  return (
    <Card
      className={cn.shadow}
      title="Search for Applications"
      headStyle={{ background: '#f5f5f5', fontWeight: 'normal' }}
    >
      <form
        className={cn.form}
        onSubmit={makeSubmitHandler((values: FilterConfig) =>
          onChange({ ...values, page: 1 }),
        )}
      >
        <Row gutter={[20, 10]}>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              mode="multiple"
              label="Country"
              options={countryOptions}
              labelCol={{ span: 24 }}
              disabled={loading}
              placeholder="Select Country"
              qaSelector="search-retailCountry"
              controllerProps={{
                name: 'retailCountry',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FInput
              allowClear
              label="Order number"
              labelCol={{ span: 24 }}
              disabled={loading}
              placeholder="Order number"
              qaSelector="search-orderNumber"
              controllerProps={{
                name: 'orderNumber',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FInput
              allowClear
              label="Ext. financing ref #"
              labelCol={{ span: 24 }}
              disabled={loading}
              placeholder="Ext. financing ref #"
              qaSelector="search-externalFinancingRefNumber"
              controllerProps={{
                name: 'externalFinancingRefNumber',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              label="Financing type"
              disabled={loading}
              placeholder="Select financing type"
              options={[
                { value: 'NONE', label: 'None' },
                ...labelsToOptions(FINANCING_TYPE_LABELS),
              ]}
              labelCol={{ span: 24 }}
              qaSelector="search-financingType"
              controllerProps={{
                name: 'financingType',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FInput
              allowClear
              label="Stock ID"
              labelCol={{ span: 24 }}
              disabled={loading}
              qaSelector="search-stockNumber"
              placeholder="Stock ID"
              controllerProps={{
                name: 'stockNumber',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              label="Flow type"
              disabled={loading}
              placeholder="Select flow type"
              options={financeFlowOptions}
              labelCol={{ span: 24 }}
              qaSelector="search-flow-type"
              controllerProps={{
                name: 'flowType',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              label="Application type"
              disabled={loading}
              placeholder="Select application type"
              options={labelsToOptions(APPLICATION_TYPE_LABELS)}
              labelCol={{ span: 24 }}
              qaSelector="search-applicationType"
              controllerProps={{
                name: 'applicationType',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FInput
              allowClear
              label="Customer lastname"
              labelCol={{ span: 24 }}
              disabled={loading}
              placeholder="Customer lastname"
              qaSelector="search-customerLastName"
              controllerProps={{
                name: 'customerLastName',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FInput
              allowClear
              type="email"
              label="Customer email"
              labelCol={{ span: 24 }}
              disabled={loading}
              placeholder="Customer email"
              qaSelector="search-customerEmail"
              controllerProps={{
                name: 'customerEmail',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              mode="multiple"
              label="Fin. application status"
              placeholder="Select finance application status"
              options={financingApplicationStatuses}
              labelCol={{ span: 24 }}
              disabled={loading}
              qaSelector="search-financingStatus"
              controllerProps={{
                name: 'status',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              mode="multiple"
              label="Order Status"
              placeholder="Select Order Status"
              options={retailOrderOptions}
              labelCol={{ span: 24 }}
              disabled={loading}
              qaSelector="search-orderState"
              controllerProps={{
                name: 'orderState',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              mode="multiple"
              label="Payment Status"
              placeholder="Select Payment Status"
              options={labelsToOptions(PAYMENT_STATUS_LABELS)}
              labelCol={{ span: 24 }}
              disabled={loading}
              qaSelector="search-paymentStatus"
              controllerProps={{
                name: 'paymentStatus',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              label="Plan Type"
              placeholder="Select Plan Type"
              options={planTypesOptions}
              labelCol={{ span: 24 }}
              disabled={loading}
              qaSelector="search-planType"
              controllerProps={{
                name: 'planType',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <FRangeDatePicker
              allowClear
              disabled={loading}
              name={['financingCreatedOnStart', 'financingCreatedOnEnd']}
              label="Fin. Application Date"
              labelCol={{ span: 24 }}
              format={FINANCING_APPLICATION_DATE_FORMAT}
              control={control}
              setValue={setValue}
              qaSelector="search-financingCreatedOn"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <FRangeDatePicker
              allowClear
              disabled={loading}
              name={['bankSubmissionDateStart', 'bankSubmissionDateEnd']}
              label="Bank submission date"
              labelCol={{ span: 24 }}
              format={FINANCING_APPLICATION_DATE_FORMAT}
              control={control}
              setValue={setValue}
              qaSelector="search-bankSubmissionDate"
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              label="Insurance"
              placeholder="Select Insurance"
              options={insuranceOptions}
              labelCol={{ span: 24 }}
              disabled={loading}
              qaSelector="search-insurance"
              controllerProps={{
                name: 'insurance',
                control,
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              mode="multiple"
              disabled={loading}
              labelCol={{ span: 24 }}
              label="Assignee"
              placeholder="Select Assignee"
              options={financingRelatedOptions.financingAgents}
              controllerProps={{
                name: 'assignee',
                control,
              }}
              qaSelector="search-assignee"
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              mode="multiple"
              disabled={loading}
              labelCol={{ span: 24 }}
              label="Sales Agent"
              placeholder="Select Sales Agent"
              options={financingRelatedOptions.saleAgents}
              controllerProps={{
                name: 'salesAgent',
                control,
              }}
              qaSelector="search-salesAgent"
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              showSearch
              mode="multiple"
              disabled={loading}
              labelCol={{ span: 24 }}
              label="Financing Provider"
              placeholder="Select Financing Provider"
              options={financingRelatedOptions.financingProviders}
              controllerProps={{
                name: 'financingProvider',
                control,
              }}
              qaSelector="search-financingProvider"
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              disabled={loading}
              labelCol={{ span: 24 }}
              label="Verification Form"
              placeholder="Select Verification Form"
              options={labelsToOptions(VERIFICATION_STATE_LABELS)}
              controllerProps={{
                name: 'verificationState',
                control,
              }}
              qaSelector="search-verificationState"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <FRangeDatePicker
              allowClear
              disabled={loading}
              name={['handoverDateStart', 'handoverDateEnd']}
              label="Handover Date"
              labelCol={{ span: 24 }}
              format={FINANCING_APPLICATION_DATE_FORMAT}
              control={control}
              setValue={setValue}
              qaSelector="search-handoverDate"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <FRangeDatePicker
              allowClear
              disabled={loading}
              name={['bankContractSignedOnStart', 'bankContractSignedOnEnd']}
              label="Bank Contract Signed On"
              labelCol={{ span: 24 }}
              format={FINANCING_APPLICATION_DATE_FORMAT}
              control={control}
              setValue={setValue}
              qaSelector="search-bankContractSignedOn"
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <FSelect
              allowClear
              disabled={loading}
              labelCol={{ span: 24 }}
              label="Customer signed bank release"
              placeholder="Yes/No"
              options={[
                { value: 'YES', label: 'Yes' },
                { value: 'NO', label: 'No' },
              ]}
              controllerProps={{
                name: 'bankReleaseSigned',
                control,
              }}
              qaSelector="search-bankReleaseSigned"
            />
          </Col>
          <Col span={24} className={cn.btn}>
            <Tooltip placement="top" title="Clear Search">
              <Button
                type="default"
                className={cn.clearBtn}
                disabled={loading}
                data-qa-selector="clearSearch"
                onClick={handleFilterReset}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Apply Search">
              <Button
                htmlType="submit"
                type="primary"
                disabled={loading}
                data-qa-selector="applySearch"
              >
                <SearchOutlined />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </form>
    </Card>
  );
};
