import { useCallback, useMemo, useState } from 'react';
import ReactHelmet from 'react-helmet';
import { Button, Row, Col, Tooltip, Pagination, notification } from 'antd';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import * as ROUTES from '../../../routes';
import { useFinancingList } from '../hooks/useFinancingList';

import { FinancingTable } from './financing-table';
import { Search } from './search';
import cn from './styles.less';
import { FilterConfig, SortingConfig, TableColumn } from './types';
import { useColumnsConfig, ColumnsConfigModal } from './columns-config';

import { getUserAccessibleCountryCodeOptions } from '~utils/accessible-countries';
import useUser from '~hooks/useUser';

const pageSizeOptions = [10, 25, 50, 100];

const defaultFilter: Required<FilterConfig> = {
  page: 1,
  pageSize: 100,
  flowType: null,
  status: [],
  financingType: null,
  applicationType: null,
  financingCreatedOnStart: null,
  financingCreatedOnEnd: null,
  retailCountry: [],
  externalFinancingRefNumber: null,
  orderNumber: null,
  stockNumber: null,
  customerEmail: null,
  customerLastName: null,
  orderState: [],
  paymentStatus: [],
  planType: null,
  ppi: false,
  gap: false,
  insurance: null,
  assignee: [],
  verificationState: null,
  financingProvider: [],
  salesAgent: [],
  bankReleaseSigned: null,
  handoverDateStart: null,
  handoverDateEnd: null,
  bankContractSignedOnStart: null,
  bankContractSignedOnEnd: null,
  bankSubmissionDateStart: null,
  bankSubmissionDateEnd: null,
};

export const List = () => {
  const location = useLocation();
  const { language } = useParams();
  const navigate = useNavigate();
  const [ctrl, setCtrl] = useState<FilterConfig>(() => ({
    ...defaultFilter,
    ...ROUTES.FINANCING_MANAGEMENT.QUERY(location.search),
  }));
  const [sortCtrl, setSortCtrl] = useState<null | SortingConfig>(
    () =>
      ROUTES.FINANCING_MANAGEMENT.QUERY(location.search).sort ?? {
        column: TableColumn.AppCreationDate,
        order: 'descend',
      },
  );
  const [showColumnsConfig, setShowColumnsConfig] = useState(false);
  const columnsConfig = useColumnsConfig();
  const { accessParameters } = useUser();
  const userAccessibleCountryOptions = useMemo(
    () => getUserAccessibleCountryCodeOptions(accessParameters),
    [accessParameters],
  );
  const financing = useFinancingList({
    filter: ctrl,
    sorting: sortCtrl,
    countryAccess: userAccessibleCountryOptions,
    onError: (error) => {
      notification.error({
        message: 'Error',
        description:
          error?.graphQLErrors?.[0]?.message ?? 'Something went wrong',
      });
    },
  });

  const updateCtrl = useCallback(
    (filter: FilterConfig, sorting?: null | SortingConfig) => {
      const nextFilter = { ...ctrl, ...filter };
      // Reset sorting if a null is explicitly provided
      const nextSorting = sorting === null ? null : sorting ?? sortCtrl;

      setCtrl(nextFilter);
      setSortCtrl(nextSorting);

      navigate(
        ROUTES.FINANCING_MANAGEMENT.LINK(
          { language },
          { ...nextFilter, sort: nextSorting },
        ),
      );
    },
    [navigate, language, ctrl, sortCtrl],
  );

  const handlePageChange = useCallback(
    (value, pageSize) => {
      updateCtrl({ page: pageSize !== ctrl.pageSize ? 1 : value, pageSize });
    },
    [ctrl.pageSize, updateCtrl],
  );

  const pagination = (
    <Pagination
      disabled={financing.loading}
      defaultPageSize={100}
      pageSizeOptions={pageSizeOptions}
      current={ctrl.page}
      pageSize={ctrl.pageSize}
      total={financing.total}
      data-qa-selector="pagination"
      onChange={handlePageChange}
    />
  );

  return (
    <>
      <ColumnsConfigModal
        isOpen={showColumnsConfig}
        initialColumnsConfig={columnsConfig.config}
        onClose={() => setShowColumnsConfig(false)}
        onColumnsConfigApply={(config) => {
          columnsConfig.setConfig(config);
          setShowColumnsConfig(false);
        }}
      />
      <div style={{ padding: 20 }} className={cn.page}>
        <ReactHelmet title="Financing management overview" />
        <Row
          gutter={16}
          justify="space-between"
          align="middle"
          style={{ marginBottom: 16 }}
        >
          <Col>
            <h1
              className={cn.title}
              data-qa-selector="title"
              style={{ marginBottom: 0 }}
            >
              Financing Management Overview
            </h1>
          </Col>
          <Col>
            <Tooltip placement="left" title="Configure table columns">
              <Button
                icon={<SettingOutlined />}
                data-qa-selector="columns-config-open"
                onClick={() => setShowColumnsConfig(true)}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={20} className={cn.offsetBottom}>
          <Col span={24}>
            <Search
              loading={financing.loading}
              defaultFilter={defaultFilter}
              initialFilter={ctrl}
              countryOptions={userAccessibleCountryOptions}
              onChange={updateCtrl}
            />
          </Col>
        </Row>
        <Row gutter={20} className={cn.offsetBottom}>
          <Col span={8} className={cn.centering}>
            <h3 className={cn.subheading} data-qa-selector="total-results">
              {`${financing.total ?? '-'} Total results`}
            </h3>
          </Col>
          <Col span={16} className={cn.pagination}>
            {pagination}
          </Col>
        </Row>
        <Row className={cn.offsetBottom}>
          <Col span={24}>
            <FinancingTable
              loading={financing.loading}
              columns={columnsConfig.visibleColumns}
              sorting={sortCtrl}
              list={financing.list}
              onSortingChange={(sorting) => updateCtrl(ctrl, sorting)}
              onItemUpdate={financing.handleItemUpdate}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} className={cn.pagination}>
            {pagination}
          </Col>
        </Row>
      </div>
    </>
  );
};
