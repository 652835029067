import { MinusOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { startCase, toLower, upperFirst } from 'lodash/fp';

type Props = {
  paymentType?: string;
  qaSelector?: string;
};

export const PaymentType = ({ paymentType, qaSelector }: Props) => (
  <Tag color="#222" data-qa-selector={qaSelector}>
    {paymentType ? (
      upperFirst(toLower(startCase(paymentType)))
    ) : (
      <MinusOutlined />
    )}
  </Tag>
);
