import { getFinancingStatuses } from './getFinancingStatuses';

import { useFetchFinancingDataForStatusTransitionQuery } from '~apollo/gql-types';
import { useHasPermission } from '~hooks/acl/useHasPermission';
import {
  createFilter,
  createFilterPayload,
  FilterType,
} from '~utils/gql-helpers';

export const useFinancingDataForStatusTransition = ({
  orderId,
  financingId,
  financingType,
  currentStatus,
  skip,
}: {
  orderId: string;
  financingId: string;
  financingType: string;
  currentStatus: string;
  skip?: boolean;
}) => {
  const hasPermission = useHasPermission();

  const {
    data,
    loading: isLoading,
    refetch,
  } = useFetchFinancingDataForStatusTransitionQuery({
    skip,
    fetchPolicy: 'cache-first',
    variables: {
      orderId,
      search: createFilterPayload({
        filter: createFilter('orderId', FilterType.EQUAL, orderId),
      }),
    },
  });

  return {
    isLoading,
    refetch,
    data: data
      ? {
          isActiveFinancing: data.orderFinancing?.id === financingId,
          availableStatuses: getFinancingStatuses({
            currentStatus,
            financingType,
            hasPermission,
            previousStatus: data.orderFinancingChangelog?.entities?.[1]?.status,
            financingFlowVersion: data.orderFinancing?.financingFlowVersion,
          }),
          cancelReason: data.orderFinancing?.cancelReason,
          rejectReason: data.orderFinancing?.rejectReason,
        }
      : null,
  };
};
