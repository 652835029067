import { ApolloProvider } from '@apollo/client';
import { ConfigProvider, notification } from 'antd';
import { useDevAuthToken } from '@retail/dev-auth';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import routes from '../../routes';

import { ANTD_EXTERNAL_VERSION_LESS } from './constants';

import './styles.less';

import { client } from '~apollo/gql-client';
import { UserProvider } from '~providers/UserProvider';

notification.config({
  top: 55,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`,
});

const formConfig = {
  validateMessages: {
    required: 'Field is required',
  },
};

export default function Root() {
  useDevAuthToken();
  return (
    <div id="financing-management">
      <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS} form={formConfig}>
        <ApolloProvider client={client}>
          <UserProvider>
            <BrowserRouter>
              <Routes>
                {routes.map(({ route, element }) => (
                  <Route
                    key={route.ROUTE}
                    path={route.ROUTE}
                    element={element}
                  />
                ))}
              </Routes>
            </BrowserRouter>
          </UserProvider>
        </ApolloProvider>
      </ConfigProvider>
    </div>
  );
}
