export enum PERMISSION {
  QUERY_ORDER = 'query/order',
  QUERY_AD = 'query/ad',
  QUERY_RETAIL_AD_BY_ID = 'query/retailadbyadid',
  QUERY_SEARCH_ORDER_FINANCING_BY_ORDER = 'query/searchorderfinancingbyorder',
  QUERY_CUSTOMER_LEAD_OVERVIEW = 'CustomerLead.getCustomerLeadOverview',
  QUERY_ORDER_FINANCE_CANCEL_REASONS = 'query/orderfinancecancelreasons',
  QUERY_ORDER_FINANCE_REJECT_REASONS = 'query/orderfinancerejectreasons',
  MUTATION_GENERATE_PDF_DOCUMENT = 'mutation/generatepdfdocument',
  MUTATION_ASSIGN_FINANCING_AGENT = 'mutation/assignfinancingagent',
  MUTATION_UPDATE_ORDER_FINANCING = 'mutation/updateorderfinancing',
  MUTATION_CANCEL_ORDER_FINANCING = 'mutation/cancelorderfinancing',
  MUTATION_REJECT_ORDER_FINANCING = 'mutation/rejectorderfinancing',
  MUTATION_PRE_APPROVE_ORDER_FINANCING = 'mutation/preapproveorderfinancing',
  MUTATION_APPROVE_ORDER_FINANCING = 'mutation/approveorderfinancing',
  MUTATION_COMPLETE_ORDER_FINANCING = 'mutation/completeorderfinancing',
  MUTATION_SUPER_EDIT_RISK_USER_INFO = 'mutation/supereditexternalriskuserinfo',
  SET_BANK_CONTRACT_SIGNED_ON = 'mutation/setbankcontractsignedon',
  MUTATION_CREATE_OR_UPDATE_EXTERNAL_FINANCING = 'mutation/createorupdateexternalfinancingoptions',
}
