import { Alert, Button, Form, Space, Spin, Tag } from 'antd';
import { useState } from 'react';

import { PopoverForm } from '../popover-form';

import { FormFields } from './FormFields';
import { FormValues } from './types';
import { useFinancingDataForStatusTransition } from './useFinancingDataForStatusTransition';

import { FinancingFragment as Financing } from '~apollo/gql-types';
import { financingApplicationStatuses } from '~constants/financing-application-statuses';
import { PERMISSION } from '~constants/permission';
import { DOMAIN } from '~constants/domain';
import useCheckPermissions from '~hooks/acl/useCheckPermissions';

type Props = {
  item: Financing;
  qaSelector?: string;
  onSave: (
    values: FormValues & { orderId: string; financingId: string },
  ) => Promise<unknown>;
};

export const ApplicationState = ({ item, qaSelector, onSave }: Props) => {
  const [formInstance] = Form.useForm<FormValues>();
  const canUpdateFinancing = useCheckPermissions({
    allow: PERMISSION.MUTATION_UPDATE_ORDER_FINANCING,
    domain: DOMAIN.ORDER_MANAGEMENT,
  }).isAllowed;

  const { status: currentStatus, orderId, financingId } = item;

  const [isPopupOpen, setPopupOpen] = useState(false);

  const {
    data: transitionData,
    isLoading,
    refetch: refetchData,
  } = useFinancingDataForStatusTransition({
    orderId,
    financingId,
    financingType: item.financingType,
    currentStatus,
    skip: !isPopupOpen,
  });

  const handleSubmit = (values: FormValues) =>
    onSave({ ...values, orderId, financingId }).finally(() => refetchData());

  const statusLabel =
    financingApplicationStatuses.find((it) => it.value === currentStatus)
      ?.label ?? currentStatus;

  const selectedStatus = Form.useWatch<string>('status', formInstance);

  if (canUpdateFinancing) {
    const isActiveFinancing = transitionData?.isActiveFinancing;
    return (
      <PopoverForm
        title="Change status"
        formProps={{
          labelAlign: 'left',
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        }}
        formInstance={formInstance}
        formFields={(() => {
          if (isLoading) {
            return (
              <Space
                direction="vertical"
                align="center"
                style={{ width: '100%' }}
              >
                <Spin />
              </Space>
            );
          }

          if (!transitionData) {
            return null;
          }

          return isActiveFinancing ? (
            <FormFields
              currentStatus={currentStatus}
              financingType={item.financingType}
              {...transitionData}
            />
          ) : (
            <Alert
              type="info"
              description="This financing application is not the latest for it's order"
            />
          );
        })()}
        submitDisabled={!isActiveFinancing || selectedStatus === currentStatus}
        onSubmit={handleSubmit}
        onPopoverVisibilityChange={(isVisible) => {
          setPopupOpen(isVisible);
          if (isVisible) {
            formInstance.setFieldsValue({
              status: currentStatus,
            });
          }
        }}
      >
        <Button
          size="small"
          type="primary"
          style={{ backgroundColor: '#222' }}
          data-qa-selector={qaSelector}
        >
          {statusLabel}
        </Button>
      </PopoverForm>
    );
  }

  return (
    <Tag color="#222" data-qa-selector={qaSelector}>
      {statusLabel}
    </Tag>
  );
};
