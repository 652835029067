import { Tag } from 'antd';

import { financeFlowOptions } from '~constants/flow-types';

type Props = {
  flowType: string;
  qaSelector?: string;
};

const FlowTypeToText = financeFlowOptions.reduce((acc, curr) => {
  acc[curr.value] = curr.label;
  return acc;
}, {});

export const FlowType = ({ flowType, qaSelector }: Props) => (
  <Tag color="#222" data-qa-selector={qaSelector}>
    {FlowTypeToText[flowType]}
  </Tag>
);
