import { ApolloError } from '@apollo/client';

import {
  CountryOption,
  FilterConfig,
  SortingConfig,
  TableColumn,
} from '../list/types';
import { formToMeta } from '../list/formToMeta';

import { createSort } from '~utils/gql-helpers';
import {
  FinancingFragment as Financing,
  SearchFinancingsDocument,
  SearchFinancingsQuery,
  useSearchFinancingsQuery,
} from '~apollo/gql-types';

interface ApplyFinancingQueryPatchInput {
  financingId: string;
  query: SearchFinancingsQuery;
  patch: Partial<Financing>;
}

interface UseFinancingListInput {
  countryAccess: CountryOption[];
  filter: FilterConfig;
  sorting: SortingConfig;
  onError?: (err: ApolloError) => void;
}

const prepareSort = (sorting: SortingConfig) => {
  const field = {
    [TableColumn.Cluster]: 'cluster',
    [TableColumn.PriorityRank]: 'order.priorityRank',
    [TableColumn.AppCreationDate]: 'createdAt',
    [TableColumn.HandoverDate]: 'order.handoverDate',
    [TableColumn.WorkingDaysUntilHandover]: 'order.handoverDate',
    [TableColumn.BankContractSignedOn]: 'bankContractSignedOn',
    [TableColumn.OrderState]: 'order.state',
    [TableColumn.AppState]: 'status',
  }[sorting.column];

  return field ? [createSort(field, sorting.order)] : null;
};

const applyFinancingQueryPatch = ({
  query,
  financingId,
  patch,
}: ApplyFinancingQueryPatchInput): SearchFinancingsQuery => ({
  ...query,
  searchFinancings: query.searchFinancings && {
    ...query.searchFinancings,
    entities: query.searchFinancings.entities?.map((it) =>
      it.financingId === financingId
        ? {
            ...it,
            ...patch,
          }
        : it,
    ),
  },
});

export const useFinancingList = ({
  countryAccess,
  filter,
  sorting,
  onError,
}: UseFinancingListInput) => {
  const queryVariables = {
    search: {
      ...formToMeta(filter, countryAccess),
      sorts: sorting ? prepareSort(sorting) : null,
    },
  };
  const { client, data, loading } = useSearchFinancingsQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
    onError,
  });

  const handleItemUpdate = (financingId: string, patch: Partial<Financing>) => {
    client.cache.updateQuery<SearchFinancingsQuery>(
      { query: SearchFinancingsDocument, variables: queryVariables },
      (query) =>
        query &&
        applyFinancingQueryPatch({
          financingId,
          query,
          patch,
        }),
    );
  };

  return {
    loading,
    total: data?.searchFinancings?.totalEntityCount,
    list: data?.searchFinancings?.entities,
    handleItemUpdate,
  };
};
