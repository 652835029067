import { useState } from 'react';
import { MinusOutlined } from '@ant-design/icons';
import { Select } from 'antd';

import { formatFullName } from '../utils';

import { FinancingFragment as Financing } from '~apollo/gql-types';

type Option = Record<'value' | 'label', string>;

interface AssigneeProps {
  allowSettingAssignee: boolean;
  country: undefined | null | string;
  orderId: undefined | null | string;
  assignee: undefined | null | Financing['assignee'];
  financingAgentsByCountry: Record<string, Option[]>;
  qaSelector?: string;
  onFinancingAgentAssign: (
    agent: null | Required<Financing['assignee']>,
  ) => Promise<void>;
}

export const Assignee = ({
  allowSettingAssignee,
  country,
  assignee,
  financingAgentsByCountry,
  qaSelector,
  onFinancingAgentAssign,
}: AssigneeProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const assignees = financingAgentsByCountry[country];

  if (!allowSettingAssignee || !assignees?.length) {
    return (
      <span data-qa-selector={qaSelector}>
        {(assignee && formatFullName(assignee)) ?? <MinusOutlined />}
      </span>
    );
  }

  const handleFinancingAgentAssign = (_, option: undefined | Option) => {
    const agent: null | Required<Financing['assignee']> = (() => {
      if (!option) {
        return null;
      }

      const [firstName, lastName] = option.label.split(' ');

      return {
        __typename: 'AssigneeSearchProjection',
        id: option.value,
        firstName,
        lastName,
      };
    })();

    setIsUpdating(true);
    onFinancingAgentAssign(agent).finally(() => {
      setIsUpdating(false);
    });
  };

  return (
    <span data-qa-selector={qaSelector}>
      <Select
        showSearch
        allowClear
        disabled={isUpdating}
        loading={isUpdating}
        placeholder="Select assignee"
        style={{ width: '200px' }}
        options={assignees}
        value={assignee?.id ?? null}
        filterOption={(searched, { label }) =>
          label.toLowerCase().includes(searched.toLowerCase())
        }
        data-qa-selector="assignee-dropdown"
        onChange={handleFinancingAgentAssign}
      />
    </span>
  );
};
